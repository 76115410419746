@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

.footerContainer {
  display: flex;
  justify-content: center;
  padding: calc(0.7rem + 3vmin) 3vmin 1vmin 3vmin;

  p {
    text-align: center;
    font-size: calc(0.9rem + 0.5vmin);
    color: $gray;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .footerContainer {
    padding: calc(0.7rem + 1vmin) 3vmin 1vmin 3vmin;
    p {
      font-size: 1em;
    }
  }
}
