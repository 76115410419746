@import '../../_variables.scss';
@import "./HeroCubesMixins.scss";

//-------------------------
.shape1 {
  @include cubeArrows0()
}

.shape2 {
  @include cubeArrows90()
}

.shape3 {
  @include cubeArrows180()
}

.shape4 {
  @include cubeArrows270()
}

//-------------------------
.shape5{
  @include halfBlueCircle0()
}

.shape6 {
  @include halfBlueCircle90()
}

.shape7 {
  @include halfBlueCircle180()
}

.shape8 {
  @include halfBlueCircle270()
}

//-------------------------
.shape9 {
  @include blueHalfCircle0()
}

.shape10 {
  @include blueHalfCircle90()
}

.shape11 {
  @include blueHalfCircle180()
}

.shape12 {
  @include blueHalfCircle270()
}

//-------------------------
.shape13 {
  @include halfRectangle0()
}

.shape14 {
  @include halfRectangle90()
}

.shape15 {
  @include halfRectangle180()
}

.shape16 {
  @include halfRectangle270()
}

//-------------------------
.shape17 {
  @include quarterCircle0()
}

.shape18 {
  @include quarterCircle90()
}

.shape19 {
  @include quarterCircle180()
}

.shape20 {
  @include quarterCircle270()
}

//-------------------------
.shape21 {
  @include halfCircleHalfBlueReverse0()
}

.shape22 {
  @include halfCircleHalfBlueReverse90()
}

.shape23 {
  @include halfCircleHalfBlueReverse180()
}

.shape24 {
  @include halfCircleHalfBlueReverse270()
}

//-------------------------
.shape25 {
  @include quarterCircleBlue0()
}

.shape26 {
  @include quarterCircleBlue90()
}

.shape27 {
  @include quarterCircleBlue180()
}

.shape28 {
  @include quarterCircleBlue270()
}

//-------------------------
.shape29 {
  @include halfTriangleHalfBlueReverse0()
}

.shape30 {
  @include halfTriangleHalfBlueReverse90()
}

.shape31 {
  @include halfTriangleHalfBlueReverse180()
}

.shape32 {
  @include halfTriangleHalfBlueReverse270()
}

//-------------------------
.shape33 {
  @include BlueTriangle0()
}

.shape34 {
  @include BlueTriangle90()
}

.shape35 {
  @include BlueTriangle180()
}

.shape36 {
  @include BlueTriangle270()
}

//-------------------------
.shape37 {
  @include BlueTriangleReverse0()
}

.shape38 {
  @include BlueTriangleReverse90()
}

.shape39 {
  @include BlueTriangleReverse180()
}

.shape40 {
  @include BlueTriangleReverse270()
}

//-------------------------
.shape41 {
  @include diagonalTriangle0()
}

.shape42 {
  @include diagonalTriangle90()
}

.shape43 {
  @include diagonalTriangle180()
}

.shape44 {
  @include diagonalTriangle270()
}

//-------------------------
.shape45 {
  @include centerArrows0()
}

.shape46 {
  @include centerArrows90()
}

//-------------------------
.shape47 {
  @include whiteCircle0()
}

//-------------------------
.shape48 {
  @include blueCircle0()
}

