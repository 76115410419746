@import '../../_variables.scss';
@import "./HeroCubes.scss";

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

@keyframes animIn {
  0% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

@keyframes animOut {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(90deg);
  }
}

.animIn {
  animation: animIn 0.6s ease 0s 1 normal forwards;
  will-change: transform;
}

.animOut {
  animation: animOut 0.3s linear 0s 1 normal forwards;
  will-change: transform;
}

.cubesMobile {
  cursor: pointer;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.cubesTablet {
  cursor: pointer;
  display: none;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.cubesDesktop {
  cursor: pointer;
  display:none;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.cube1{
  grid-area: cube1;
}

.cube2{
  grid-area: cube2;
}

.cube3{
  grid-area: cube3;
}

.cube4{
  grid-area: cube4;
}

.cube5{
  grid-area: cube5;
}

.cube6{
  grid-area: cube6;
}

.cube7{
  grid-area: cube7;
}

.cube8{
  grid-area: cube8;
}

.cube9{
  grid-area: cube9;
}

.cube10{
  grid-area: cube10;
}

.cube11{
  grid-area: cube11;
}

.cube12{
  grid-area: cube12;
}

.cube13{
  grid-area: cube13;
}

.cube14{
  grid-area: cube14;
}

.cube15{
  grid-area: cube15;
}

.cube16{
  grid-area: cube16;
}

.cube17{
  grid-area: cube17;
}

.cube18{
  grid-area: cube18;
}

.cube19{
  grid-area: cube19;
}

.cube20{
  grid-area: cube20;
}

.cube21{
  grid-area: cube21;
}

.cube22{
  grid-area: cube22;
}

.cube23{
  grid-area: cube23;
}

.cube24{
  grid-area: cube24;
}

.cube25{
  grid-area: cube25;
}

.cube26{
  grid-area: cube26;
}

.cube27{
  grid-area: cube27;
}

.cube28{
  grid-area: cube28;
}

.cube29{
  grid-area: cube29;
}

.cube30{
  grid-area: cube30;
}

.cube31{
  grid-area: cube31;
}

.cube32{
  grid-area: cube32;
}

.cube33{
  grid-area: cube33;
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .cubesTablet {
    display: flex;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1025px) {
  .cubesDesktop {
    display: flex;
  }
}