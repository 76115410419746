@import '../../_variables.scss';
@import './HeroCubesSettings.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

.heroContainer {
	display: grid;
	justify-content: center;
	width: 100vw;
	grid-template-columns: repeat(4, 25vmin);
	grid-template-rows: repeat(6, 25vmin);
	grid-template-areas: "cube1 cube2 cube3 cube4" "cube5 . cube6 cube7" "header header header header"
		"header header header header" "cube8 cube9 . cube10" "cube11 cube12 cube13 cube14";
}

.headerContainer {
	grid-area: header;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		font-size: calc(0.5rem + 3.2vmin);
		margin-bottom: calc(0.1rem + 0.9vmin);
	}

	h1 {
		font-size: calc(0.8rem + 6.3vmin);
		font-weight: 900;
		margin-bottom: 4vmin;
		white-space: nowrap;
	}
}

.btnContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0 3vmin;

	.projectBtn {
		cursor: pointer;
		text-decoration: none;
		color: black;
		font-size: calc(0.9rem + 1vmin);
		background-color: $accent;
		border-radius: 3px;
		padding: calc(0.3rem + 1vmin) calc(0.7rem + 2.5vmin);
		border: 2px solid transparent;

		&:hover {
			color: $accent;
			background-color: transparent;
			border: 2px solid $accent;
		}
	}

	.contactBtn {
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;
		color: black;
		font-size: calc(0.9rem + 1vmin);

		.contactIcon {
			height: calc(0.9rem + 1vmin);
			width: calc(0.9rem + 1vmin);
			margin-right: 1vmin;
		}

		&:hover {
			color: $accent;
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
	.heroContainer {
		grid-template-columns: repeat(7, 20vmin);
		grid-template-rows: repeat(4, 20vmin);
		grid-template-areas: 'cube1 . cube2 cube3 cube4 cube5 cube6' 'header header header header header header header'
			'header header header header header header header' 'cube7 cube8 . cube9 cube10 cube11 cube12';
	}

	.btnContainer {
		justify-content: center;
		gap: 10vmin;
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
	.heroContainer {
		grid-template-columns: repeat(6, 16.6vmin);
		grid-template-rows: repeat(7, 16.6vmin);
		grid-template-areas: 'cube1 . cube2 cube3 cube4 cube5' 'cube6 cube7 . cube8 . cube9'
			'cube10 cube11 cube12 cube13 cube14 cube15' 'header header header header header header'
			'header header header header header header' 'cube16 cube17 cube18 cube19 cube20 cube21'
			'cube22 . cube23 cube24 cube25 cube26';
	}

	.btnContainer {
		justify-content: center;
		gap: 7vmin;
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
	.heroContainer {
		margin: auto;
		grid-template-columns: repeat(8, 12.3vmin);
		grid-template-rows: repeat(7, 12.3vmin);
		grid-template-areas: 'cube1 cube2 cube3 cube4 cube5 cube6 cube7 cube8'
			'cube9 cube10 cube11 . cube12 cube13 cube14 cube15' '. . . cube16 cube17 . cube18 cube19'
			'header header header header header header . cube20' 'header header header header header header cube21 cube22'
			'. . . cube23 . cube24 cube25 cube26' 'cube27 . cube28 cube29 cube30 cube31 cube32 cube33';
	}

	.headerContainer {
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: calc(0.1rem + 0.9vmin);

		p {
			font-size: calc(0.5rem + 2.5vmin);
			margin-bottom: calc(0.1rem + 0.3vmin);
		}

		h1 {
			font-size: calc(0.2rem + 6.8vmin);
			margin-bottom: 3.1vmin;
		}
	}

	.btnContainer {
		justify-content: flex-start;
		padding: 0;
		gap: 5vmin;

		.projectBtn {
			font-size: calc(0.5rem + 1.6vmin);
			padding: calc(0.1rem + 1vmin) calc(0.6rem + 2.5vmin);

			&:active {
				transform: scale(0.95);
				color: $accent;
			}
		}

		.contactBtn {
			display: flex;
			align-items: center;
			font-size: calc(0.5rem + 1.6vmin);

			.contactIcon {
				height: calc(0.5rem + 1.6vmin);
				width: calc(0.5rem + 1.6vmin);
				margin-right: 0.7vmin;
			}

			&:active {
				transform: scale(0.93);
				color: $accent;
			}
		}
	}
}
