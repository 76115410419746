@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE
.linkContainer {
  display: none;
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px){

  .linkContainer {
    display: flex;
    justify-content: center;
    padding: 2.5vmin 3vmin;
    gap: calc(1.5rem + 3vmin);

    .link {
      color: $gray;
      text-decoration: none;
      font-size: 1.13em;

      &:hover {
        color: $accent;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .navContainer {
    display: flex;
    width: 98.4vmin;
    justify-content: flex-end;
    margin: auto;
  }

  .linkContainer {
    gap: calc(1rem + 2vmin);
    padding: 2vmin 0;

    .link {
      font-size: calc(0.8rem + 0.5vmin);

      &:active {
        transform: scale(0.88);
        color: $accent;
      }
    }

  }
}