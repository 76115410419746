@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

.aboutContainer {
  padding: calc(3rem + 5vmin) 3vmin 0 3vmin;
}

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: calc(1.5rem + 3vmin);

  h2 {
    font-size: calc(1.8rem + 0.7vmin);
    font-weight: 700;
  }

  .forwardIcon {
    color: $pink;
    width: calc(2rem + 1vmin);
    height: calc(2rem + 1vmin);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .textColumn {
    display: flex;
    flex-direction: column;
    gap: calc(0.6rem + 0.1vmin);
  }

  .text {
    font-size: calc(0.9rem + 0.5vmin);
  }

  .whySection {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: calc(1.2rem + 0.4vmin);
      margin-top: calc(2rem + 1.5vmin);
      margin-bottom: calc(0.6rem + 0.8vmin);
      color: $pink;
      white-space: nowrap;
    }
  }

  .elementContainer {
    display: flex;
    flex-direction: column;
    row-gap: calc(0.7rem + 0.3vmin);
    width: 80vmin;

    .element{
      display: flex;
      align-items: center;
      column-gap: 0.7rem;

      .elementBox {
        min-width: calc(0.8rem + 0.2vmin);
        height: calc(0.8rem + 0.2vmin);
        background-color: $blue;
      }

      p {
        font-size: calc(0.9rem + 0.5vmin);
        white-space: break-spaces;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
  .aboutContainer {
    padding: calc(3rem + 5vmin) 0 0 0;
    width: 140vmin;
    margin: auto;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .aboutContainer {
    padding: calc(3rem + 5vmin) 3vw 0 3vw;
    width: 100%;
    margin: 0;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {

  .aboutContainer {
    padding: calc(1.5rem + 10vmin) 0 0 0;
  }


  .headerContainer {
    width: 98.4vmin;
    margin: 0 auto calc(1.5rem + 2vmin) auto;

    h2 {
      font-size: calc(0.7rem + 2vmin);
      padding-left: 0;
    }

    .forwardIcon {
      width: calc(1rem + 2.5vmin);
      height: calc(1rem + 2.5vmin);
    }
  }

  .textContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(80rem + 3vmin);
    margin: auto;

    .textColumn {
      gap: calc(0.3rem + 0.2vmin);
    }

    .text {
      width: calc(40rem + 1.5vmin);
      font-size: 1.13em;
    }

    .whySection {
      flex-direction: row;
      width: calc(40rem + 1.5vmin);

      h3 {
        flex-grow: 1;
        width: fit-content;
        padding: 0 2rem;
        margin: 0;
        font-size: 1.25em;
        text-align: center;
      }
    }

    .elementContainer {
      .element {
        .elementBox {
          width: 1em;
          height: 1em;
          background-color: $blue;
          margin-right: 0.4rem;
        }

        p {
          font-size: 1.13em;
        }
      }
    }
  }
}

