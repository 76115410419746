@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

.techContainer {
  padding: calc(3rem + 5vmin) 3vmin 0 3vmin;
}

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: calc(1.5rem + 3vmin);

  h2 {
    font-size: calc(1.8rem + 0.7vmin);
    font-weight: 700;
  }

  .forwardIcon {
    color: $pink;
    width: calc(2rem + 1vmin);
    height: calc(2rem + 1vmin);
  }
}

.iconsContainer {
  display: flex;
  justify-content: space-between;

  .column {
    display: flex;
    flex-direction: column;
    gap: 5vmin;

    .item {
      display: flex;
      align-items: center;
      color: $gray;

      .icon {
        width: calc(1rem + 3vmin);
        height: calc(1rem + 3vmin);
        margin-right: 1.5vmin;
      }

      p {
        font-size: calc(0.7rem + 1vmin);
      };
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
  .techContainer {
    padding: calc(3rem + 5vmin) 0 0 0;
    width: 140vmin;
    margin: auto;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .techContainer {
    padding: calc(3rem + 5vmin) 3vw 0 3vw;
    width: 100%;
    margin: 0;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .techContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: calc(1.5rem + 10vmin) 0 0 0;
    width: 98.4vmin;
    margin: auto;
  }

  .headerContainer {
    margin: 0;

    h2 {
      font-size: calc(0.7rem + 2vmin);
    }

    .forwardIcon {
      width: calc(1rem + 2.5vmin);
      height: calc(1rem + 2.5vmin);
    }
  }

  .iconsContainer {
    width: 61.5vmin;
    margin-top: calc(2.5rem + 3vmin);

    .column {
      gap: 2.5vmin;

      .item {
        .icon {
          width: calc(0.4rem + 2.5vmin);
          height: calc(0.4rem + 2.5vmin);
          margin-right: 0.7vmin;
        }

        p {
          font-size: 1em;
        }
      }
    }
  }
}