@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

.contactsContainer {
  padding: calc(3rem + 5vmin) 3vmin 0 3vmin;
}

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: calc(1.5rem + 3vmin);

  h2 {
    font-size: calc(1.8rem + 0.7vmin);
    font-weight: 700;
  }

  .forwardIcon {
    color: $pink;
    width: calc(2rem + 1vmin);
    height: calc(2rem + 1vmin);
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .element {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(2rem + 1.5vmin);

    .networkIcon {
      width: calc(1.6rem + 2.5vmin);
      height: calc(1.6rem + 2.5vmin);
      margin-bottom: calc(0.2rem + 0.4vmin);
      color: $gray;
    }

    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: calc(0.9rem + 1vmin);
      color: $link;

      &:hover {
        color: $accent;
      }
    }

    .emailBtn {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: calc(0.9rem + 1vmin);
      font-family: 'Montserrat', sans-serif;
      text-decoration: underline;
      color: $link;

      &:hover {
        color: $accent;
      }
    }

    .linkIcon {
      margin-left: 0.3rem;
    }
  }
}

.emailError {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: calc(0.9rem + 1vmin);
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  color: black;
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
  .contactsContainer {
    padding: calc(3rem + 5vmin) 0 0 0;
    width: 140vmin;
    margin: auto;
  }

  .linksContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .contactsContainer {
    padding: calc(3rem + 5vmin) 3vw 0 3vw;
    width: 100%;
    margin: 0;
  }

  .linksContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .contactsContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: calc(1.5rem + 10vmin) 0 0 0;
    width: 98.4vmin;
    margin: auto;
  }

  .headerContainer {
    margin: 0;

    h2 {
      font-size: calc(0.7rem + 2vmin);
    }

    .forwardIcon {
      width: calc(1rem + 2.5vmin);
      height: calc(1rem + 2.5vmin);
    }
  }

  .linksContainer {
    align-items: flex-start;
    width: 61.5vmin;

    .element {
      margin-top: calc(2.5rem + 3vmin);
      margin-bottom: calc(2.5rem + 3vmin);

      .networkIcon {
        width: calc(0.6rem + 3vmin);
        height: calc(0.6rem + 3vmin);
        margin-bottom: calc(0.2rem + 0.4vmin);
      }

      a {
        font-size: calc(0.7rem + 0.8vmin);

        &:active {
          transform: scale(0.93);
          color: $accent;
        }
      }

      .emailBtn {
        font-size: calc(0.7rem + 0.8vmin);

        &:active {
          transform: scale(0.93);
          color: $accent;
        }
      }

      .linkIcon {
        margin-left: 0.5vmin;
      }
    }

    .emailError {
      font-size: calc(0.7rem + 0.8vmin);
    }
  }
}