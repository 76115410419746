@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateY(-250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cardsContainer {
  padding: 0 3vmin;
}

.card {
  display: grid;
  grid-template-areas: 'img' 'tech' 'h3' 'p' 'btnContainer';
  width: 100%;
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.35);
  padding: 4vmin;
  border-radius: 3px;
  margin: calc(1rem + 3vmin) 0;
  background-color: white;

  img {
    grid-area: img;
    width: 100%;
    height: auto;
    border-radius: 3px;
  }

  h3 {
    grid-area: h3;
    font-size: calc(1.2rem + 0.4vmin);
    margin-top: 0.7rem;
    margin-bottom: 1rem;
    color: $gray;
  }

  .tech {
    grid-area: tech;
    font-size: calc(0.8rem + 0.4vmin);
    margin-top: 0.4rem;

    color: $gray;
  }

  .shortText {
    grid-area: p;
    font-size: calc(0.9rem + 0.5vmin);
  }

  .moreBtn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $gray;
    font-size: calc(0.9rem + 0.5vmin);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-left: 0.2rem;

    &:hover {
      color: $accent;
    }
  }
}

.btnContainer {
  grid-area: btnContainer;
  display:flex;
  align-items: flex-end;
  margin-top: 1.5rem;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: calc(1.5rem + 2vmin);
  }

  .demoBtn {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    background-color: $accent;
    border-radius: 3px;
    font-size: calc(0.9rem + 1vmin);
    padding: calc(0.3rem + 0.5vmin) calc(0.7rem + 2.2vmin);
    border: 0.2vmin solid transparent;

    .demoIcon {
      margin-right: 0.2rem;
    }

    &:hover {
      color: $accent;
      background-color: transparent;
      border: 2px solid $accent;
    }
  }

  .gitBtn {
    display: flex;
    align-items: center;
    color: $gray;
    text-decoration: none;
    font-size: calc(0.9rem + 1vmin);

    .gitIcon {
      margin-right: 0.2rem;
    }

    &:hover {
      color: $accent;
    }
  }
}

.hidden {
  display: none;
}

.moreSpan {
  margin-left: 0.3em;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
  .cardsContainer {
    padding: 0;
    width: 140vmin;
    margin: auto;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .cardsContainer {
    padding: 0 3vw;
    width: 100%;
    margin: 0;
  }

  .card {
    grid-template-areas: 'h3 img' 'p img' 'btnContainer img' 'btnContainer tech';
    grid-template-columns: 60% 40%;
    grid-template-rows: auto auto 1fr;
    height: fit-content;

    h3 {
      margin-top: 0;
      margin-right: 1rem;
    }

    .tech {
      text-align: end;
    }

    .shortText {
      margin-right: 1rem;
    }

    .btnContainer {
      margin-right: 1rem;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .cardsContainer {
    display: grid;
    grid-template-columns: 40rem 40rem;
    grid-template-rows: auto;
    justify-content: center;
    gap: calc(0.2rem + 1.8vw);
    padding: calc(1rem + 2vmin) 0;

    .card {
      margin:0;
      padding: 2vmin;
      grid-template-columns: 50% 50%;

      h3 {
        font-size: calc(0.9rem + 0.7vmin);
      }

      .tech {
        font-size: 0.94em;
        margin-top: 0.4rem;

      }

      .shortText {
        font-size: 1em;
      }

      .moreBtn {
        font-size: 0.94em;

        &:active {
          transform: scale(0.93);
          color: $accent;
        }
      }
    }

    .btnContainer {
      .buttons {
        gap: 1.2rem;
      }

      .demoBtn {
        font-size: calc(1rem + 0.2vmin);
        padding: calc(0.3rem + 0.2vmin) calc(1rem + 0.8vmin);

        &:active {
          transform: scale(0.93);
          color: $accent;
        }
      }

      .gitBtn {
        font-size: calc(1rem + 0.2vmin);

        &:active {
          transform: scale(0.93);
          color: $accent;
        }
      }
    }
  }
}