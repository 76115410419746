@import './_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,500&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  background-color: $bg;
  font-family: 'Montserrat', sans-serif;
  overflow-x: clip;
}
