@import '../../_variables.scss';

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE
.projectsContainer {
  padding-top: calc(3rem + 5vmin);
}

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: calc(1.5rem + 3vmin);

  h2 {
    font-size: calc(1.8rem + 0.7vmin);
    font-weight: 700;
    padding-left: 3vmin;
  }

  .forwardIcon {
    color: $pink;
    width: calc(2rem + 1vmin);
    height: calc(2rem + 1vmin);
  }
}

.contentsContainer {
  border: 0.1px solid transparent;
  position: relative;
  width: 100vw;

  .pinkSquare {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 60%;
    background-color: $pink;
    mix-blend-mode: multiply;
    z-index:-100;
  }

  .blueSquare {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 75%;
    height: 60%;
    background-color: $blue;
    mix-blend-mode: multiply;
    z-index:-100;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// MOBILE - HORIZONTAL
@media screen and (max-height: 567px) {
  .projectsContainer {
    padding-top: calc(3rem + 5vmin);
  }

  .headerContainer {
    padding: 0;
    width: 140vmin;
    margin: 0 auto calc(1.5rem + 3vmin) auto;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// TABLET
@media screen and (min-height: 568px) and (min-width: 613px) {
  .projectsContainer {
    padding-top: calc(3rem + 5vmin);
  }

  .headerContainer {
    padding: 0;
    width: 100%;
    margin: 0 0 calc(1.5rem + 3vmin) 0;

    h2 {
      padding-left: 3vw;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
// DESKTOP + IPAD PRO HORIZONTAL
@media screen and (min-height: 568px) and (min-width: 1366px) {
  .projectsContainer {
    padding-top: calc(1.5rem + 10vmin);
  }

  .headerContainer {
    width: 98.4vmin;
    margin: 0 auto calc(1.5rem + 2vmin) auto;

    h2 {
      font-size: calc(0.7rem + 2vmin);
      padding-left: 0;
    }

    .forwardIcon {
      width: calc(1rem + 2.5vmin);
      height: calc(1rem + 2.5vmin);
    }
  }

  .contentsContainer {
    .pinkSquare {
      right: calc((100vw - 98.4vmin)/2);
      width: auto;
    }

    .blueSquare {
      left: calc((100vw - 98.4vmin)/2);
      width: auto;
    }
  }
}

